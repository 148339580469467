/* ==========================================================================
   Components Pages - Page Collection Video
   ========================================================================== */
@import '../../../../scss/theme-bootstrap';

.overlay--collection-video {
  #cboxContent,
  #cboxLoadedContent {
    background: rgba(#000, 0.6);
  }
  #cboxClose {
    color: $color--black;
    z-index: 20;
    height: 40px;
    -webkit-transform: translate3d(0, 0, 1px);
  }
  &.inverted-close #cboxClose {
    color: $color--white;
  }
  #cboxLoadedContent {
    @include swap_direction(padding, 0);
    overflow: hidden !important;
  }
  @include transform(translateY(-100%));
  @include transition(transform 1.4s);
  &.active {
    @include transform(translateY(0));
  }
}

.collection-video-player {
  &__media {
    position: relative;
  }
  &__image {
    display: block;
    width: 100%;
  }
  .eyebrow {
    @include breakpoint($bp--medium-up) {
      margin-top: -10%;
      position: static;
      margin-bottom: 3em;
    }
  }
  .text {
    position: absolute;
    top: 50%;
    #{$ldirection}: 50%;
    @include transform(translate(flipX(-50%, #{$rdirection}), -50%));
    text-align: center;
  }
  iframe.text {
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .play {
    position: absolute;
    @include swap_direction(margin, 0);
    clear: none;
    display: block;
    z-index: 10;
    font-size: 94px;
    top: 50%;
    #{$ldirection}: 50%;
    margin-top: -47px;
    margin-#{$ldirection}: -47px;
    color: $color--white;
  }

  // One time exception using js- rule in SCSS, in this case the play functionality is tied to the display logic
  .js-play {
    cursor: pointer;
  }
}
